<template>
  <div :class="$style.container">
    <Icon name="plus" :class="$style.plus" @click="$emit('delete')"></Icon>
    <div :class="$style.image">
      <a :href="$configData.s3_link + link" download="">
        <img :src="image" alt="file-image" />
      </a>
    </div>
    <form :class="$style.form" @submit.prevent="$emit('changeName', value)">
      <input ref="name" :class="$style.file" v-model="value" />
      <button type="submit">Сохранить</button>
    </form>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'

export default {
  components: { Icon },
  props: {
    link: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    mimeType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      image: '',
      value: this.text
    }
  },
  created() {
    this.showFile(this.mimeType)
  },
  methods: {
    showFile(mimeType) {
      switch (mimeType) {
        case 'text/csv':
          this.image = '/img/downloads/csv.webp'
          break
        case 'text/rtf':
          this.image = '/img/downloads/rtf2.webp'
          break
        case 'text/html':
          this.image = '/img/downloads/html.webp'
          break
        case 'text/plain':
          this.image = '/img/downloads/txt.webp'
          break
        case 'image/gif':
          this.image = '/img/downloads/gif.webp'
          break
        case 'image/bmp':
          this.image = '/img/downloads/bmp.webp'
          break
        case 'image/png':
          this.image = '/img/downloads/png.webp'
          break
        case 'image/jpeg':
          this.image = '/img/downloads/jpeg.webp'
          break
        case 'application/csv':
          this.image = '/img/downloads/csv1.webp'
          break
        case 'application/rtf':
          this.image = '/img/downloads/rtf.webp'
          break
        case 'application/pdf':
          this.image = '/img/downloads/pdf.webp'
          break
        case 'xls':
          this.image = '/img/downloads/xls.webp'
          break
        case 'application/vnd.ms-excel':
          this.image = '/img/downloads/xls.webp'
          break
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
          this.image = '/img/downloads/xlsx.webp'
          break
        case 'application/msword':
          this.image = '/img/downloads/doc.webp'
          break
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
          this.image = '/img/downloads/docx.webp'
          break
        case 'application/x-zip-compressed':
          this.image = '/img/downloads/zip.webp'
          break
        default:
          this.image = '/img/downloads/default.webp'
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/mixins.scss';

.container {
  @include card;
  position: relative;
  max-width: 22rem;
  .plus {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    transform: rotate(45deg);
    fill: $dark-gray;
    &:hover {
      fill: $black-gray;
    }
  }
  .image {
    max-width: 18.5rem;
    margin: 0 auto;
    height: 9rem;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  .form {
    display: flex;
    justify-content: center;
    padding: 0 0.3rem;
    .file {
      color: $black-gray;
      padding: 0.5rem;
    }
  }
}
</style>
