<template>
  <div :class="$style.container">
    <Loader :isLoading="isLoader" />
    <h2 :class="$style.title">{{ list.name }}</h2>
    <button :class="$style.create" @click="isShow = true">
      <Icon role="button" name="plus" :class="$style.plus" />
      Добавить файл
    </button>
    <form v-if="isShow" :class="$style.form" @submit.prevent="submitForm">
      <Icon name="plus" :class="$style.plus" @click="isShow = false"></Icon>
      <h2>Добавить файл</h2>
      <input
        ref="file"
        type="file"
        :class="$style.input"
        placeholder="Добавить файл"
        @change="changeFile"
      />
      <input
        type="text"
        placeholder="Имя файла"
        :class="$style.input"
        v-model="form.name"
      />
      <br />
      <Button type="submit">Добавить</Button>
      <div v-if="error" :class="$style.error">
        {{ error }}
      </div>
    </form>
    <div :class="$style.cards">
      <DownloadCard
        v-for="(file, index) in files"
        :key="index"
        :text="file.name"
        :link="file.link"
        :mime-type="file.mimeType"
        @delete="deleteFile(file.id, index)"
        @changeName="changeFileName(file.id, $event)"
      />
    </div>
  </div>
</template>

<script>
import DownloadCard from '@/components/atoms/cards/DownloadCard.vue'
import Icon from '@/components/atoms/Icon'
import Button from '@/components/atoms/Button.vue'
import Loader from '@/components/atoms/LoadingBar.vue'
import delivery from '@/delivery'

export default {
  components: { DownloadCard, Icon, Button, Loader },
  mounted() {
    this.getGroupById(this.$route.params.id)
    this.getFiles(this.$route.params.id)
  },
  methods: {
    async getGroupById(id) {
      const { value, error } =
        await delivery.AddwineCore.DownloadsActions.getById(id)
      if (error) return
      this.list = value
    },
    async submitForm() {
      if (this.form.data) {
        this.isLoader = true
        const result = await delivery.AddwineCore.DownloadsActions.creatFile({
          groupId: this.form.groupId,
          name: this.form.name,
          data: this.form.data,
        })
        if (result.error) {
          console.error(result.error)
          alert(result.error)
        } else {
          this.clearForm()
          this.getFiles(this.$route.params.id)
        }
        this.isLoader = false
      }
    },
    changeFile(event) {
      const reader = new FileReader()
      if (event.target.files[0].size > 20971520) {
        alert('Вес файла слишком большой, максимальный вес 20мб')
        this.$refs.file.value = null
        return
      }
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
        this.form.data = reader.result
      }
      this.form.name = event.target.files[0].name
    },
    async changeFileName(id, name) {
      const result = await delivery.AddwineCore.DownloadsActions.changeFileName(
        id,
        {
          groupId: this.form.groupId,
          name,
        },
      )
      if (result.error) {
        console.error(result.error)
        alert('Ошибка изменения имени файла!')
      }
    },
    clearForm() {
      this.form.name = ''
      this.form.data = ''
      this.$refs.file.value = null
    },
    async getFiles(group_id) {
      this.isLoader = true
      const result =
        await delivery.AddwineCore.DownloadsActions.getFilesByGroupId({
          group_id: group_id,
        })
      this.files = result.value
      this.isLoader = false
    },
    async deleteFile(id) {
      this.isLoader = true
      const result = await delivery.AddwineCore.DownloadsActions.deleteFile(id)
      if (result.error) {
        console.error(result.error)
        alert('Ошибка удаления файла!')
      } else {
        this.getFiles(this.$route.params.id)
      }
      this.isLoader = false
    },
  },

  data() {
    return {
      isShow: false,
      error: '',
      title: '',
      files: [],
      list: [],
      form: {
        groupId: this.$route.params.id,
        name: '',
        data: '',
      },
      file: {
        groupId: this.$route.params.id,
        name: '',
        data: '',
      },
      isLoader: false,
    }
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
  .title {
    margin: 1.5rem 0 3rem 0;
    color: $black-gray;
  }
  .cards {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
  .create {
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border: 1px solid $light-gray;
    margin: 2rem 0;
    cursor: pointer;
    gap: 0.5rem;
    text-decoration: none;
    color: $black-gray;
    &:hover {
      background: $white;
    }
  }
  .form {
    background: $white;
    padding: 2rem;
    max-width: 30rem;
    margin-bottom: 3rem;
    position: relative;
    h2 {
      color: $black-gray;
      margin-bottom: 1.5rem;
    }
    .input {
      display: inline-flex;
      align-items: center;
      padding: 0.5rem 1rem;
      border: 1px solid $light-gray;
      margin: 0 0 1rem 0;
      cursor: pointer;
      gap: 0.5rem;
      text-decoration: none;
      color: $black-gray;
      &:hover {
        background: $white;
      }
    }
    .plus {
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      transform: rotate(45deg);
      fill: $dark-gray;
      &:hover {
        fill: $black-gray;
      }
    }
  }
  .plus {
    fill: $black-gray;
    width: 1rem;
    height: 1rem;
  }
}
</style>
